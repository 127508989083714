import { Link } from '@remix-run/react';
import { CircleXIcon } from 'lucide-react';

type BackdropLayoutProps = {
  children: React.ReactNode;
  to?: string;
};

export function BackdropLayout({ children, to = '/' }: BackdropLayoutProps) {
  return (
    <div className={`h-[calc(100dvh-58px)] flex-grow`}>
      <div className={`absolute inset-0 z-10 bg-blue-500/10 backdrop-blur-[2px] `}></div>
      <div className={`relative z-50 flex h-full flex-col bg-gray-1`}>
        <h1 className={`mx-auto w-fit py-20 font-mono text-2xl font-semibold uppercase tracking-[0.5rem]`}>
          <Link to={`/`}>Exposit</Link>
        </h1>

        {children}

        {to === 'back()' ? (
          <button onClick={() => history.back()} className={`absolute left-4 top-4 rounded-full bg-indigo-4`}>
            <CircleXIcon size={20} />
          </button>
        ) : (
          <Link to={to} className={`absolute left-4 top-4 rounded-full bg-indigo-4`}>
            <CircleXIcon size={20} />
          </Link>
        )}
      </div>
    </div>
  );
}
